import { Channels, channels, paymentTypeToTranslationDict } from "CONSTANTS";
import { OrderForInvoicing } from "api/orders/models";
import { CountryFlag } from "components/common/countryFlag/CountryFlag";
import { PaymentStatusTag } from "components/common/listRow/paymentLabel/PaymentLabel";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { Link } from "components/miloDesignSystem/atoms/link";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { orderConstants } from "constants/orders";
import { createDraftDocumentUtils } from "pages/tradingDocuments/createDraftDocument/utils";
import { dateFns } from "utilities";
import styles from "../../AddOrdersToDraft.module.css";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useOrdersColumns = (
  areAllOrdersInvoiced: boolean,
  areAllSelected: boolean,
  areSomeOrdersSelected: boolean,
  isOrderSelected: (id: number) => boolean,
  setAllOrders: () => void,
  setOrder: (id: number) => void,
) => {
  return useCreateTableColumns<OrderForInvoicing>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        id: "selectOrders",
        header: () => {
          return (
            <div className="d-flex align-items-end">
              <Checkbox
                checked={areAllSelected}
                disabled={areAllOrdersInvoiced}
                indeterminate={areSomeOrdersSelected && !areAllSelected}
                onChange={setAllOrders}
                size="sx"
              />
            </div>
          );
        },
        size: 25,
        cell: info => {
          const order = info.getValue();
          return (
            <Checkbox
              disabled={createDraftDocumentUtils.areAllPositionsAlreadyInvoiced(order)}
              checked={isOrderSelected(order.id)}
              onChange={() => setOrder(order.id)}
              size="sx"
            />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "sygnatura",
        size: 130,
        cell: info => {
          const order = info.getValue();
          return (
            <Link
              fontSize="14"
              fontWeight="500"
              target="_blank"
              to={`/orders/list/active/all?panelId=${order.id}`}
            >
              {order.signature}
            </Link>
          );
        },
      }),
      columnHelper.accessor(row => row.created, {
        header: "utworzono",
        size: 130,
        cell: info => {
          const created = info.getValue();
          return (
            <Typography fontSize="14" fontWeight="600">
              {dateFns.formatRelative(new Date(created), "dd.MM.yyyy")}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.salesAccount.channel, {
        header: "kanał sprzedaży",
        size: 85,
        cell: info => {
          const channel = info.getValue() as Channels;
          if (channels[channel] && Boolean(channels[channel].icon)) {
            return <img alt="" className={styles.channelIcon} src={channels[channel].icon} />;
          }
          return (
            <Typography fontSize="14" fontWeight="600">
              {channel}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.status, {
        header: "status",
        size: 120,
        cell: info => {
          const status = info.getValue();
          return (
            <Tag
              label={orderConstants.orderStatusVariants[status].label}
              type={orderConstants.orderStatusVariants[status].type}
              variant={orderConstants.orderStatusVariants[status].variant}
            />
          );
        },
      }),
      columnHelper.accessor(row => row.payment.currency, {
        header: "waluta",
        size: 50,
        cell: info => {
          const currency = info.getValue();
          return <CountryFlag currency={currency} />;
        },
      }),
      columnHelper.text(row => (row.customer ? row.customer.name : null), {
        header: "kontrahent",
        size: 130,
      }),
      columnHelper.accessor(row => row.invoices, {
        header: "dokumenty powiązane",
        size: 365,
        cell: info => {
          const invoices = info.getValue();
          if (!Boolean(invoices.length))
            return <EmptyValue color="neutralBlack88" fontSize="14" fontWeight="700" />;
          return (
            <div className="d-flex align-items-center gap-1">
              <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
                {invoices[0].signature}
              </Typography>
              {Boolean(invoices[0].recipient.firstName || invoices[0].recipient.lastName) && (
                <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
                  [{invoices[0].recipient.firstName} {invoices[0].recipient.lastName}]
                </Typography>
              )}
              {invoices.length > 1 && (
                <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
                  + {invoices.length - 1}
                </Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.payment, {
        header: "płatność",
        size: 150,
        cell: info => {
          const payment = info.getValue();
          return (
            <div className="d-flex align-items-center gap-2">
              <Typography color="neutralBlack88" fontSize="14" fontWeight="600" noWrap>
                {paymentTypeToTranslationDict[payment.type]}
              </Typography>
              <PaymentStatusTag paymentStatus={payment.status} />
            </div>
          );
        },
      }),
    ];
  });
};
