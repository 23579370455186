import { Order } from "api/orders/models";
import { Decimal } from "api/types";
import { useSelector } from "hooks";

interface OrderItemAmountDTO {
  amount: Decimal;
  packages: {
    id: number;
    isAddedToComplaint: boolean;
  }[];
  quantity: number;
}
type OrderItemAmount = number;
/**
 * @description whenever we need to calculate the total amount of items in the order, we can use this hook
 */
const useGetOrderItemsAmount = () => {
  const isUsingPartialComplaints = useSelector(
    state => state.partials.configuration.isUsingPartialComplaints,
  );

  return (orderItems: OrderItemAmountDTO[]): OrderItemAmount => {
    const itemsAmount: number = orderItems.reduce(
      (acc, item) =>
        acc +
        Number(item.amount) *
          (isUsingPartialComplaints && item.packages.some(el => el.isAddedToComplaint)
            ? 1
            : item.quantity),
      0,
    );
    return Number(itemsAmount.toFixed(2));
  };
};

const isOrderValueForPaidOrderValid = ({
  itemsValue,
  orderPayment,
  deliveryAmount,
}: {
  itemsValue: OrderItemAmount;
  orderPayment: Order["payment"];
  deliveryAmount: Order["delivery"]["amount"];
}): boolean => {
  if (orderPayment.status !== "PAID") return true;

  if (
    getOrderPriceWithDeliveryAndDiscount({
      deliveryAmount,
      discount: orderPayment.discount,
      itemsAmount: itemsValue,
    }) === Number(orderPayment.paidAmount)
  )
    return true;
  if (
    getOrderPriceWithDeliveryAndDiscount({
      deliveryAmount,
      discount: orderPayment.discount,
      itemsAmount: itemsValue,
    }) > Number(orderPayment.paidAmount)
  )
    return false;

  return true;
};

const getOrderPriceWithDeliveryAndDiscount = ({
  deliveryAmount,
  discount,
  itemsAmount,
}: {
  deliveryAmount: string;
  discount: number | string;
  itemsAmount: number;
}) => {
  return Number(Number(itemsAmount + Number(deliveryAmount) - Number(discount)).toFixed(2));
};

// TODO: We need to unify price calculations
// https://linear.app/imilo/issue/MIL-1335/funkcja-w-orders-utils-do-obliczania-roznicy-kwot
const getMiloPriceWithDiscountDeliveryAndCarrying = ({
  deliveryAmount,
  discountAmount,
  itemsCarryingAmount,
  orderItemsAmount,
}: {
  deliveryAmount: Order["delivery"]["amount"];
  discountAmount: Order["payment"]["discount"];
  itemsCarryingAmount: number | string;
  orderItemsAmount: OrderItemAmount;
}): number => {
  return (
    parseDecimal(orderItemsAmount) +
    parseDecimal(itemsCarryingAmount) +
    parseDecimal(deliveryAmount) -
    parseDecimal(discountAmount)
  );
};

const parseDecimal = (value: string | number): number => {
  return Number(Number(value).toFixed(2));
};

//TEMP name, we need to write it better! @łukasz
export const orderUtils = {
  getMiloPriceWithDiscountDeliveryAndCarrying,
  getOrderPriceWithDeliveryAndDiscount,
  isOrderValueForPaidOrderValid,
  parseDecimal,
  useGetOrderItemsAmount,
};
