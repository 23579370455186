import styles from "../RightPanel.module.css";
import cx from "classnames";
import { TradingDocument } from "api/trading-documents/models";
import { dateUtils, isCountryCodeInEU } from "utilities";
import { RightPanelSection } from "components/utils/drawer";
import { useViesSectionActions } from "./hooks/useViesSection";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiRestartAlt } from "components/miloDesignSystem/atoms/icons/MdiRestartAlt";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  tradingDocument: TradingDocument;
}

export const ViesSection = ({ tradingDocument }: Props) => {
  const { viesStatusMutation, whiteListStatusMutation } = useViesSectionActions(tradingDocument.id);

  if (!isCountryCodeInEU(tradingDocument.countryCode)) return null;
  if (!tradingDocument.buyer?.taxId && !tradingDocument.buyerTaxId) return null;

  return (
    <RightPanelSection>
      <div className="d-flex gap-5 py-3 borderTop borderBottom">
        <div>
          <div className="d-flex align-items-center gap-2 pb-2">
            <Typography className={cx(styles.viesTitle, "pl-0")} fontSize="16" fontWeight="700">
              VIES
            </Typography>
            <IconButton
              icon={MdiRestartAlt}
              isLoading={viesStatusMutation.isLoading}
              onClick={() => viesStatusMutation.mutate({ id: tradingDocument.id })}
              variant="gray"
            />
          </div>
          <div className="d-flex align-items-center body-10 gap-2">
            <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
              ostatnio sprawdzony:
            </Typography>
            <Typography fontSize="10" fontWeight="500">
              {tradingDocument.viesCheckedAt
                ? dateUtils.formatDateToDisplayOnlyDate(tradingDocument.viesCheckedAt)
                : EMPTY_VALUE}
            </Typography>
          </div>
          <div className="d-flex align-items-center body-10 gap-2">
            <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
              wówczas:
            </Typography>
            {!tradingDocument.buyerTaxId ? (
              <Typography fontSize="10" fontWeight="500">
                Brak NIP-u
              </Typography>
            ) : (
              <Typography
                fontSize="10"
                fontWeight={tradingDocument.viesValidStatus === "INVALID" ? "700" : "500"}
                color={
                  tradingDocument.viesValidStatus === "INVALID" ? "danger500" : "neutralBlack100"
                }
              >
                {tradingDocument.viesValidStatus
                  ? getViesDictBasedOnStatus(tradingDocument.viesValidStatus)
                  : EMPTY_VALUE}
              </Typography>
            )}
          </div>
          {tradingDocument.viesValidStatus === "INVALID" && tradingDocument.buyerTaxId && (
            <Typography fontSize="10" fontWeight="500" className="mt-1" color="danger500">
              VAT może być niepoprawny. Sprawdź poprawność danych.
            </Typography>
          )}
        </div>
        {tradingDocument.countryCode === "PL" && (
          <div>
            <div className="d-flex align-items-center gap-2 pb-2">
              <Typography className={cx(styles.viesTitle, "pl-0")} fontSize="16" fontWeight="700">
                Biała lista
              </Typography>
              <IconButton
                icon={MdiRestartAlt}
                isLoading={whiteListStatusMutation.isLoading}
                onClick={() => whiteListStatusMutation.mutate({ id: tradingDocument.id })}
                variant="gray"
              />
            </div>
            <div className="d-flex align-items-center body-10 gap-2">
              <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                ostatnio sprawdzony:
              </Typography>
              <Typography fontSize="10" fontWeight="500">
                {tradingDocument.whiteListCheckedAt
                  ? dateUtils.formatDateToDisplayOnlyDate(tradingDocument.whiteListCheckedAt)
                  : EMPTY_VALUE}
              </Typography>
            </div>
            <div className="d-flex align-items-center body-10 gap-2">
              <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                wówczas:
              </Typography>
              <Typography fontSize="10" fontWeight="500">
                {tradingDocument.whiteListValidStatus
                  ? getViesDictBasedOnStatus(tradingDocument.whiteListValidStatus)
                  : EMPTY_VALUE}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </RightPanelSection>
  );
};

const getViesDictBasedOnStatus = (status: TradingDocument["viesValidStatus"]): string => {
  switch (status) {
    case "ACTIVE":
      return "Aktywny";
    case "INACTIVE":
      return "Nieaktywny";
    case "UNREGISTERED":
      return "Niezarejestrowany";
    case "NO_DATA":
      return "Brak danych";
    case "NO_TAX_ID":
      return "Brak NIP-u";
    case "INVALID":
      return "Błąd odczytu vies";
    default:
      const exhaustiveCheck = status;
      console.error(`Unhandled vies status: ${exhaustiveCheck}`);
      return EMPTY_VALUE;
  }
};
