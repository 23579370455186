import { useQuery } from "hooks";
import { getSearch } from "./utils/getSearch";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { Table } from "components/miloDesignSystem/molecules/table";
import styles from "./LongHeldPackages.module.css";
import {
  NormalizedLongHeldPackage,
  normalizeLongHeldPackages,
  useLongHeldPackagesColumns,
} from "./useLongHeldPackagesColumns";
import { uniquePackagesActions } from "api/unique-packages/actions";
import { LongHeldPackageTab } from "api/unique-packages/models";
import { withFilters } from "components/utils/withFilters";
import { longHeldPackagesFilters } from "./tableFilters";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { RouteComponentProps } from "react-router";
import { useMemo, useState } from "react";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { getDateRangeSimpleList } from "utilities/getDateRangesForFilters";
import { getTabs } from "./utils/getTabs";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { longHeldPackages } from "components/common/moduleNavigation/moduleConfig/wms/routes/longHeldPackages";
import { LongHeldPackagesTableToolbar } from "./longHeldPackagesTableToolbar/LongHeldPackagesTableToolbar";
import { useMultiSelect } from "./tableMultiSelect/useMultiSelect";
import { cx } from "utilities";

export const LongHeldPackages = withFilters(
  longHeldPackagesFilters,
  ({ match }: RouteComponentProps<{ tab: LongHeldPackageTab }>) => {
    const { tab } = match.params;
    const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
    const search = getSearch({ query, tab });
    const {
      data,
      error,
      isPreviousData,
      isLoading,
      pagination,
    } = uniquePackagesActions.useLongHeldPackages(search);
    const normalizedRows = useMemo(() => {
      return normalizeLongHeldPackages(data);
    }, [data]);
    const tableMultiSelect = useMultiSelect({ rows: normalizedRows });
    const [hoveredRow, setHoveredRow] = useState("");
    const tableProps = useTableFeatureConnector({
      rows: normalizedRows,
      withPagination: { pagination, defaultPaginationVisibility: true },
    });
    const columns = useLongHeldPackagesColumns(
      tableMultiSelect.selectedRows,
      hoveredRow,
      setHoveredRow,
    );

    return (
      <PageWrapper>
        <PageHeader
          showHistory={false}
          searchInput={{
            label: "Szukaj...",
            tags: [],
          }}
          tabs={{
            list: getTabs(),
            routesRoot: `wms/${longHeldPackages.url}`,
            urlSpan: "list",
          }}
          title="Meble długo trzymane w magazynie"
          viewLabel="PACKAGES_IN_STOCK"
          bottomBarButtons={
            <div className="d-flex align-items-center justify-content-end py-2 flex-1">
              <div className="d-flex align-items-center gap-1 ml-2">
                <Typography fontSize="10" fontWeight="700" color="neutralBlack48">
                  Wydane z:
                </Typography>
                {getDateRangeSimpleList().map(({ from, id, label, to }) => (
                  <div
                    className="cursor-pointer d-flex"
                    key={id}
                    onClick={() => {
                      if (query.receivedAtFrom === from && query.receivedAtTo === to) {
                        updateQuery({ receivedAtFrom: "", receivedAtTo: "", isReleased: "" });
                        return;
                      }
                      updateQuery({ receivedAtFrom: from, receivedAtTo: to, isReleased: "true" });
                    }}
                  >
                    <Tag
                      label={label}
                      type={
                        query.receivedAtFrom === from && query.receivedAtTo === to
                          ? "filled"
                          : "outlined"
                      }
                      variant={
                        query.receivedAtFrom === from && query.receivedAtTo === to
                          ? "deepPurple50"
                          : "quaternary"
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          }
        />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<NormalizedLongHeldPackage>
            columns={columns}
            isLoading={isPreviousData || isLoading}
            error={error}
            onRowClick={(row, event) => tableMultiSelect.onRowClick(row, event)}
            uiSchema={{
              header: {
                backgroundColor: "grey25",
              },
              cell: {
                height: LONG_HELD_PACKAGES_ROW_HEIGHT,
              },
            }}
            {...tableProps}
            overrides={() => ({
              row: row => ({
                className: cx(tableMultiSelect?.overrides()?.row?.(row).className, {
                  [styles.hoveredBg]: hoveredRow === row.productId,
                }),
                disableHoverStyles: true,
                onMouseEnter: () => {
                  setHoveredRow(row.productId);
                },
                onMouseLeave: () => {
                  setHoveredRow("");
                },
              }),
            })}
          />
          <LongHeldPackagesTableToolbar
            close={tableMultiSelect.clear}
            numberOfSelectedPackages={
              [...new Set(tableMultiSelect.selectedRows.map(selectedRow => selectedRow.productId))]
                .length
            }
            packages={tableMultiSelect.selectedRows}
          />
        </div>
      </PageWrapper>
    );
  },
);

export const LONG_HELD_PACKAGES_ROW_HEIGHT = "38";
