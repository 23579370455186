import { warehousePreviewFactory } from "api/warehouse/calls";
import { wmsLayoutActions } from "api/wms/layout/actions";
import { wmsLayoutPreviewFactory } from "api/wms/layout/api";
import { wmsLayoutConstants } from "api/wms/layout/constants";
import { FieldDetails, FieldKind } from "api/wms/layout/models";
import { wmsRampsPreviewFactory } from "api/wms/ramps/api";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelHeader } from "components/utils/drawer";

interface Props {
  close: () => void;
  field: FieldDetails;
}

export const PanelHeader = ({ close, field }: Props) => {
  const handleDownloadRackPdf = wmsLayoutActions.useDownloadWarehouseSchemaRackPdf();
  const patchAreaMutation = wmsLayoutActions.useAreaPatch();

  return (
    <RightPanelHeader>
      <div className="d-flex align-items-center gap-3">
        <Typography fontSize="18" fontWeight="700">
          {wmsLayoutConstants.fieldKindDict[field.wmsKind]} {field.name}
        </Typography>
        <div className="d-flex align-items-center gap-1">
          <Typography fontSize="14" fontWeight="600">
            Strefa:
          </Typography>
          <Typography fontSize="14" fontWeight="700">
            {field.area && Boolean(field.area.name) ? `${field.area.name}` : "---"}
          </Typography>
        </div>
        <Menu
          disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
          menuItems={[
            {
              type: MenuItemType.CUSTOM_RENDERER,
              onClick: () => {
                patchAreaMutation.mutate({
                  id: field.area.id,
                  suggestLocations: !field.area.suggestLocations,
                });
              },
              renderer: () => (
                <div className="d-flex align-items-center">
                  <Checkbox.Async
                    checked={field.area.suggestLocations}
                    label="Strefa dostępna do sugerowania"
                    mutationHook={wmsLayoutActions.useAreaPatch}
                    size="sm"
                    transformQueryData={suggestLocations => ({
                      id: field.area.id,
                      suggestLocations,
                    })}
                  />
                </div>
              ),
            },
          ]}
        />
      </div>
      <div className="d-flex align-items-center justify-content-end flex-1">
        {field.wmsKind === FieldKind.RACK ? (
          <Tooltip title="Pobierz etykietę">
            <IconButton
              icon={MdiQrCode}
              onClick={() => handleDownloadRackPdf(field.wmsId, field.name)}
              variant="transparent"
            />
          </Tooltip>
        ) : (
          <Tooltip
            title={`Podgląd etykiety dla ${
              field.wmsKind === FieldKind.FIELD
                ? "miejsca"
                : field.wmsKind === FieldKind.COLUMN
                ? "kolumny"
                : "rampy"
            }`}
          >
            <IconButton
              disabled={!field.wmsId}
              icon={MdiQrCode}
              onClick={() => {
                if (field.wmsKind === FieldKind.FIELD)
                  window.open(wmsLayoutPreviewFactory.whCellsLabels(field.wmsId).url, "_blank");
                if (field.wmsKind === FieldKind.RAMP)
                  window.open(wmsRampsPreviewFactory.rampLabel(field.wmsId).url, "_blank");
                if (field.wmsKind === FieldKind.COLUMN)
                  window.open(warehousePreviewFactory.cellLabel(field.wmsId).url, "_blank");
              }}
              variant="transparent"
            />
          </Tooltip>
        )}
        <span className="divider line-divider" />
        <Tooltip title="Zamknij panel">
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </Tooltip>
      </div>
    </RightPanelHeader>
  );
};
