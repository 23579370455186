import { channels, Channels } from "CONSTANTS";
import { CreateManufacturingOrderItem, PaymentSource } from "api/external-manufacturing/models";
import { orderActions } from "api/orders/actions";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox/Checkbox";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Switch } from "components/miloDesignSystem/atoms/switch";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Select } from "components/miloDesignSystem/molecules/select";
import { countryToFlagDict } from "constants/countriesFlags";
import { isPast } from "date-fns";
import { useSelector } from "hooks";
import { PriorityLabel } from "pages/externalManufacturing/shared/components/PriorityLabel";
import { dateFns, dateUtils, pluralize } from "utilities";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styles from "./CreateExternalManufacturingOrderModal.module.css";
import {
  FormValues,
  useManufacturingOrderItemsSelectionLogic,
} from "./CreateExternalManufacturingOrderModal";
import { useFormikContext } from "formik";
import { memo, useMemo } from "react";
import { assertIsDefined } from "utilities/assertIsDefined";
import { PaymentStatusTag } from "components/common/listRow/paymentLabel/PaymentLabel";

const paymentSourceDict: Record<PaymentSource, string> = {
  ONLINE: "Przelew",
  ON_DELIVERY: "Pobranie",
};

export const useExternalManufacturingOrdersColumns = () => {
  return useCreateTableColumns<FormValues[number]>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        id: "selectOrders",
        header: () => {
          return (
            <div className="d-flex align-items-end">
              <SelectAllItemsCheckbox />
            </div>
          );
        },
        size: 25,
        cell: info => {
          const lineItem = info.getValue();
          return <SelectItemCheckbox index={info.row.index} lineItem={lineItem} />;
        },
      }),
      columnHelper.text(row => row.product.name, {
        header: "nazwa produktu",
        size: 260,
        typographyProps: {
          noWrap: "3",
        },
      }),
      columnHelper.accessor(row => row, {
        header: "producent",
        size: 110,
        cell: info => {
          const lineItem = info.getValue();
          if (lineItem.details.addedToManufacturingOrder)
            return (
              <Typography fontSize="12" fontWeight="700" noWrap>
                {lineItem.manufacturer.name}
              </Typography>
            );
          return <SelectProducer index={info.row.index} lineItem={lineItem} />;
        },
      }),
      columnHelper.text(row => row.itemNote, {
        header: "uwagi produktu",
        size: 120,
        typographyProps: {
          noWrap: "3",
        },
      }),
      columnHelper.accessor(row => row.salesChannel, {
        header: "kanał sprzedaży",
        size: 110,
        cell: info => {
          const salesChannel: CreateManufacturingOrderItem["salesChannel"] = info.getValue();
          const salesChannelName = salesChannel.name as Channels;
          return (
            <Typography.WithCustomColor
              fontSize="12"
              fontWeight="700"
              color={
                channels[salesChannelName] !== undefined
                  ? channels[salesChannelName].color
                  : "neutralBlack100"
              }
              noWrap
            >
              {channels[salesChannelName] !== undefined
                ? channels[salesChannelName].name
                : salesChannel.name}
            </Typography.WithCustomColor>
          );
        },
      }),
      columnHelper.link({
        header: "rezerwacja",
        size: 100,
        to: row => `/reservations?panelId=${row.reservation?.id}`,
        textRenderer: row => row.reservation?.signature,
        typographyProps: {
          fontSize: "14",
        },
      }),
      columnHelper.accessor(row => row, {
        id: "updateItemAutoReservations",
        header: headerContext => {
          const lineItems = headerContext.table.options.data;
          return <UpdateItemAutoReservationsHeader lineItems={lineItems} />;
        },
        size: 130,
        cell: info => {
          const lineItem = info.getValue();
          return <UpdateItemAutoReservationsBody index={info.row.index} lineItem={lineItem} />;
        },
      }),
      columnHelper.accessor(row => row.priority, {
        id: "priority",
        header: "",
        size: 25,
        cell: info => {
          const priority = info.getValue();
          return (
            <div className="w-100 d-flex align-items-center justify-content-center pr-3">
              <PriorityLabel priority={priority} />
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.payment, {
        header: "płatność",
        size: 160,
        cell: info => {
          const payment: CreateManufacturingOrderItem["payment"] = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1">
              <Typography fontSize="14" fontWeight="700">
                {paymentSourceDict[payment.type]}
              </Typography>
              <PaymentStatusTag paymentStatus={payment.status} />
            </div>
          );
        },
      }),
      columnHelper.text(row => row.order.signature, {
        header: "zamówienie",
        size: 130,
      }),
      columnHelper.accessor(row => row.order.placedAt, {
        header: "złożone",
        size: 100,
        cell: info => {
          const date: string | null = info.getValue();
          if (!date) return <EmptyValue />;
          return (
            <Typography fontSize="12" fontWeight="700">
              {dateFns.formatRelative(new Date(date), "dd.MM.yyyy", true)}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.address.countryCode, {
        header: "kraj",
        size: 60,
        cell: info => {
          const countryCode: string = info.getValue();
          if (!countryCode || !Boolean(countryCode.length)) return <EmptyValue />;
          return (
            <div className="d-flex align-items-center gap-1">
              <img alt="Flaga kraju" src={countryToFlagDict[countryCode]} />
              <Typography fontSize="12" fontWeight="700">
                {countryCode}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.order.shipmentTime, {
        header: "dni",
        size: 50,
        cell: info => {
          const days: number = info.getValue();
          return (
            <Typography fontSize="12" fontWeight="700">
              {days}{" "}
              {pluralize.pl(days, {
                singular: "dzień",
                plural: "dni",
                other: "dni",
              })}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.order.deliveryNoLaterThan, {
        header: "ostateczna data",
        size: 100,
        cell: info => {
          const date: string | null = info.getValue();
          if (!date) return <EmptyValue />;
          return (
            <Typography
              color={isPast(new Date(date)) ? "danger400" : "neutralBlack100"}
              fontSize="12"
              fontWeight="700"
            >
              {dateUtils.formatDateToDisplayOnlyDate(date)}
            </Typography>
          );
        },
      }),
      columnHelper.text(row => (row.deliveryGroup ? row.deliveryGroup.signature : null), {
        header: "trasa/grupa",
        size: 110,
      }),
      columnHelper.text(row => row.labels.length, {
        header: "l. paczek",
        size: 50,
      }),
      columnHelper.text(row => row.seller.name, {
        header: "sprzedawca",
        size: 110,
      }),
      columnHelper.accessor(row => row.details.addedToManufacturingOrder, {
        header: "status zlecenia",
        size: 90,
        cell: info => {
          const status: boolean = info.getValue();
          if (status) return <Tag label="zlecono" variant="success" />;
          return <Tag label="niezlecono" variant="warning" />;
        },
      }),
    ];
  });
};

const UpdateItemAutoReservationsHeader = ({ lineItems }: { lineItems: FormValues }) => {
  const context = useFormikContext<FormValues>();

  return (
    <div className="d-flex align-items-center">
      <UpdateItemsAutoReservations
        disabled={lineItems.every(lineItem => lineItem.details.addedToManufacturingOrder)}
        isExcludedFromReservation={lineItems.every(lineItem => lineItem.isExcludedFromReservation)}
        onSuccess={isExcludedFromReservation => {
          context.setValues(
            context.values.map(manufacturingOrderItem => ({
              ...manufacturingOrderItem,
              isExcludedFromReservation,
            })),
          );
        }}
        singleItemQuantitiesIds={lineItems
          .filter(lineItem => !lineItem.details.addedToManufacturingOrder)
          .map(lineItem => lineItem.singleItemQuantityId)}
      />
      <Typography className="ml-1" color="neutralBlack48" fontSize="12" fontWeight="400">
        blokuj rezerwacje
      </Typography>
    </div>
  );
};

const UpdateItemAutoReservationsBody = ({
  lineItem,
  index,
}: {
  lineItem: FormValues[number];
  index: number;
}) => {
  const context = useFormikContext<FormValues>();

  return (
    <UpdateItemsAutoReservations
      disabled={lineItem.details.addedToManufacturingOrder}
      key={lineItem.id}
      isExcludedFromReservation={lineItem.isExcludedFromReservation}
      onSuccess={exclude => {
        context.setFieldValue(`${index}.isExcludedFromReservation`, exclude);
      }}
      singleItemQuantitiesIds={[lineItem.singleItemQuantityId]}
    />
  );
};

const UpdateItemsAutoReservations = ({
  disabled,
  isExcludedFromReservation,
  singleItemQuantitiesIds,
  onSuccess,
}: {
  disabled: boolean;
  singleItemQuantitiesIds: CreateManufacturingOrderItem["singleItemQuantityId"][];
  isExcludedFromReservation: boolean;
  onSuccess: (exclude: boolean) => void;
}) => {
  const updateItemsAutoReservationsMutation = orderActions.useUpdateItemsAutoReservations();
  return (
    <Switch
      disabled={updateItemsAutoReservationsMutation.isLoading || disabled}
      checked={isExcludedFromReservation}
      onChange={exclude => {
        onSuccess(exclude);
        updateItemsAutoReservationsMutation.mutateAsync({
          exclude,
          singleItemQuantitiesIds,
        });
      }}
    />
  );
};

const SelectAllItemsCheckbox = () => {
  const {
    areAllItemsPreviouslyAdded,
    areAllItemsSelected,
    areSomeItemsSelected,
    setAllItemsSelection,
  } = useManufacturingOrderItemsSelectionLogic();

  return (
    <Checkbox
      checked={areAllItemsSelected}
      disabled={areAllItemsPreviouslyAdded}
      indeterminate={areSomeItemsSelected}
      onChange={setAllItemsSelection}
      size="sx"
    />
  );
};

const SelectItemCheckbox = ({
  lineItem,
  index,
}: {
  lineItem: FormValues[number];
  index: number;
}) => {
  const context = useFormikContext<FormValues>();

  return (
    <Checkbox
      disabled={lineItem.details.addedToManufacturingOrder}
      checked={lineItem.isSelected}
      onChange={value => {
        if (!lineItem.details.addedToManufacturingOrder) {
          context.setFieldValue(`[${index}].isSelected`, value);
        }
      }}
      size="sx"
    />
  );
};

const SelectProducer = memo(
  ({ index, lineItem }: { index: number; lineItem: FormValues[number] }) => {
    const context = useFormikContext<FormValues>();
    const manufacturers = useSelector(store => store.partials.manufacturers);
    const manufacturersOptions = useMemo(
      () =>
        manufacturers.map(manufacturer => ({
          text: manufacturer.name,
          type: MenuItemType.TEXT,
          value: manufacturer.id,
        })),
      [manufacturers],
    );

    return (
      <Select
        items={manufacturersOptions}
        textFieldProps={{
          containerClassName: styles.manufacturerSelector,
          inputClassName: styles.manufacturerSelectorInput,
        }}
        onChange={manufacturerId => {
          const selectedManufacturer = manufacturers.find(
            manufacturer => manufacturer.id === manufacturerId,
          );
          assertIsDefined(selectedManufacturer);
          context.setFieldValue(`[${index}].manufacturer`, {
            email: selectedManufacturer.email,
            id: selectedManufacturer.id,
            name: selectedManufacturer.name,
          });
        }}
        selected={lineItem.manufacturer.id}
      />
    );
  },
);
