import {
  CreateTradingDocumentPayment,
  CreateTradingDocumentPaymentItem,
  TradingDocument,
  TradingDocumentItem,
} from "api/trading-documents/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { FieldArray, useFormikContext } from "formik";
import styles from "../AddPaymentModal.module.css";
import { FormInput, FormSelect } from "components/utils";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { UUID } from "api/types";
import { cx } from "utilities";
import immer from "immer";
import { tradingDocumentPaymentUtils } from "pages/tradingDocuments/shared/utils/tradingDocumentPaymentUtils";

interface Props {
  tradingDocument: TradingDocument;
}

export const AdvancePaymentItems = ({ tradingDocument }: Props) => {
  const context = useFormikContext<CreateTradingDocumentPayment>();

  if (context.values.kind !== "ADVANCE") return null;

  const handleItemSelect = (index: number, id: UUID): void => {
    const foundTradingDocumentItem = tradingDocument.items
      .flatMap(item => item.tradingDocumentItems)
      .find(tradingDocumentItem => tradingDocumentItem.id === id);

    if (foundTradingDocumentItem) {
      context.setValues(
        immer(context.values, draft => {
          const itemToUpdate = draft.items[index];
          Object.assign(itemToUpdate, {
            id: foundTradingDocumentItem.id,
            totalAmount: tradingDocumentPaymentUtils.calculateItemTotalAmount({
              amountWithTaxAfterDiscount: foundTradingDocumentItem.amountWithTaxAfterDiscount,
              quantity: foundTradingDocumentItem.quantity,
            }),
            quantity: foundTradingDocumentItem.quantity,
            vatRate: foundTradingDocumentItem.vatRate,
            name: foundTradingDocumentItem.name,
            initialAmount: tradingDocumentPaymentUtils.calculateItemTotalAmount({
              amountWithTaxAfterDiscount: foundTradingDocumentItem.amountWithTaxAfterDiscount,
              quantity: foundTradingDocumentItem.quantity,
            }),
          });
        }),
      );
    }
  };

  const getNextItemAvailable = (contextItemsIds: UUID[]) => {
    const nextAvailableItem = tradingDocument.items
      .flatMap(item => item.tradingDocumentItems)
      .find(tradingDocumentItem => contextItemsIds.every(id => tradingDocumentItem.id !== id));
    return nextAvailableItem;
  };

  const normalizeTradingDocumentItem = (
    nextAvailableItem: TradingDocumentItem | undefined,
  ): CreateTradingDocumentPaymentItem | null => {
    if (!nextAvailableItem) return null;
    return {
      id: nextAvailableItem.id,
      totalAmount: tradingDocumentPaymentUtils.calculateItemTotalAmount({
        amountWithTaxAfterDiscount: nextAvailableItem.amountWithTaxAfterDiscount,
        quantity: nextAvailableItem.quantity,
      }),
      vatRate: nextAvailableItem.vatRate,
      name: nextAvailableItem.name,
      quantity: nextAvailableItem.quantity,
      initialAmount: tradingDocumentPaymentUtils.calculateItemTotalAmount({
        amountWithTaxAfterDiscount: nextAvailableItem.amountWithTaxAfterDiscount,
        quantity: nextAvailableItem.quantity,
      }),
    };
  };

  const getAdvancePositionsOptions = (contextItem: CreateTradingDocumentPaymentItem) => {
    return tradingDocument.items.flatMap(item =>
      item.tradingDocumentItems
        .filter(
          tradingDocumentItem =>
            !context.values.items
              ?.filter(formItem => formItem.id !== contextItem.id)
              .map(formItem => formItem.id)
              .includes(tradingDocumentItem.id),
        )
        .map(tradingDocumentItem => ({
          id: tradingDocumentItem.id,
          name: tradingDocumentItem.name,
        })),
    );
  };

  const isAddNewPositionButtonDisabled = (): boolean => {
    return !getNextItemAvailable(context.values.items.map(item => item.id));
  };

  return (
    <div className="mb-3 borderBottom pb-2">
      <Typography className="mb-2" color="grey500" fontSize="14" fontWeight="600">
        Pozycje faktury
      </Typography>
      <FieldArray
        name="items"
        render={({ push, remove }) => (
          <div className={styles.positionList}>
            {context.values.items &&
              context.values.items.map((contextItem, index) => (
                <div
                  className={cx(
                    "d-flex align-items-center justify-content-between mt-3 w-100",
                    styles.positionItem,
                  )}
                  key={index}
                >
                  <div className={cx(styles.select, styles.advancePositionSelect)}>
                    <FormSelect
                      items={getAdvancePositionsOptions(contextItem)}
                      itemToSelection={item => (item ? item.id : null)}
                      label="Pozycja faktury"
                      name={`items[${index}].id`}
                      onChange={item => item && handleItemSelect(index, item.id)}
                      placeholder="Wybierz pozycję faktury"
                      selectedItem={contextItem.id}
                      width="full"
                    />
                  </div>
                  <FormInput
                    className={styles.advancePositionAmount}
                    label="Kwota"
                    minimum={0}
                    maximum={context.values.items![index].initialAmount}
                    name={`items[${index}].totalAmount`}
                    placeholder="Wpisz kwotę"
                    type="number"
                  />
                  <div className={styles.advancePositionRemoveBtn}>
                    <IconButton icon={MdiDelete} onClick={() => remove(index)} variant="gray" />
                  </div>
                </div>
              ))}
            <Button
              className="text-uppercase"
              disabled={isAddNewPositionButtonDisabled()}
              onClick={() =>
                push(
                  normalizeTradingDocumentItem(
                    getNextItemAvailable(context.values.items.map(item => item.id)),
                  ),
                )
              }
              startIcon={MdiAdd}
              size="small"
              variant="gray"
            >
              Dodaj pozycję
            </Button>
          </div>
        )}
      />
    </div>
  );
};
