import { filterFactory } from "components/utils/withFilters";

export const longHeldPackagesFilters = filterFactory(({ FilterType }) => [
  {
    type: FilterType.Search,
    label: "kontrahent",
    name: "customerId",
    searchBy: "customers",
  },
  {
    type: FilterType.Number,
    label: "l. dni w magazynie mniejsza niż",
    placeholder: "0",
    name: "daysInStockLt",
  },
  {
    type: FilterType.Number,
    label: "l. dni w magazynie większa niż",
    placeholder: "0",
    name: "daysInStockGt",
  },
  {
    type: FilterType.Select,
    label: "Status wydania",
    name: "isReleased",
    options: [
      { label: "Wydane", value: "true" },
      { label: "Niewydane", value: "false" },
    ],
  },
]);
