import immer from "immer";
import { useEffect, useMemo, useState } from "react";
import { ErrorMessage, Spinner, CommonError } from "components/utils";
import { ArrayElement } from "typeUtilities";
import { Formik } from "formik";
import { OrderProductInstance, OrderProductInstanceAttribute, Order } from "api/orders/models";
import { Button } from "components/common";
import styles from "./ProductForm.module.css";
import cx from "classnames";
import { useSelector } from "hooks";
import { Assign } from "utility-types";
import { AttributeSection } from "./attributeSection/AttributeSection";
import { CURRENCY_TYPE, FLAVOR } from "CONSTANTS";
import { Product, ProductEntity } from "api/products/models";
import { SearchProduct } from "./SearchProduct";
import { assertIsDefined } from "utilities/assertIsDefined";
import cuid from "cuid";
import { SingleProductForm } from "./SingleProductForm";
import {
  getInitialStateForCreate,
  getInitialStateForEdit,
  initialProductElement,
} from "./utils/initialState";
import { generateCommonAttributesState } from "./utils/generateCommonAttributesState";
import { SubmitCreateProduct, SubmitEditProduct } from "../ProductsSection";
import { isMoreThanOneAttributeValue } from "./utils/isMoreThanOneAttributeValue";
import { queryString } from "utilities";
import { getProductIndexBasedOnAttributesState } from "./utils/getProductIndexBasedOnAttributesState";
import { productsActions } from "api/products/actions";
import { productSetActions } from "api/product-sets/actions";

export type AttributesState = {
  attributeId: number;
  valueId: number | null;
  picture: string | null;
}[];

export type AdaptedOrder = Pick<Order, "customer"> & {
  currency: Order["payment"]["currency"];
  salesAccount: { currency: Order["salesAccount"]["currency"] };
};

export type AdaptedProduct = Pick<Product, "attributes" | "picture" | "id" | "indexes">;

export type PreselectedValues = Assign<
  OrderProductInstanceAttribute,
  { value: ArrayElement<OrderProductInstanceAttribute["values"]> }
>[];

export interface Values {
  productElements: {
    cuid: string;
    id: number;
    amount: string;
    name: string;
    attributesState: AttributesState;
    quantity: number;
    productSetCode: string;
    currency: CURRENCY_TYPE;
    product: number;
    index: number | null;
    note: string;
  }[];
}

interface Props {
  closeForm?: () => void;
  isService?: boolean;
  productEntity?: Assign<ProductEntity, { products: OrderProductInstance[] }>;
  order: AdaptedOrder;
  submitEditProduct?: SubmitEditProduct;
  submitCreateProduct?: SubmitCreateProduct;
  showQuantityPicker?: boolean;
  showAmountPicker?: boolean;
  overrides?: {
    addButtonText?: string;
    removeHorizontalPadding?: boolean;
    CustomSearchProductComponent?: ({
      onChange,
    }: {
      onChange: (id: ProductEntity["id"]) => void;
    }) => React.ReactNode;
  };
}

export const ProductForm = ({
  closeForm,
  productEntity: orderProduct,
  order,
  submitEditProduct,
  submitCreateProduct,
  showAmountPicker = true,
  showQuantityPicker = true,
  overrides = {
    addButtonText: "Dodaj do zamówienia",
    removeHorizontalPadding: false,
  },
  isService = false,
}: Props) => {
  const customers = useSelector(state => state.partials.customers);

  // If there is product passed in props, it means that form has "edit" type
  const formType = orderProduct ? "edit" : "create";
  const [productToFetch, setProductToFetch] = useState<any>(
    orderProduct?.products[0].product ?? null,
  );

  const canUseAllProductsWithCustomer = useSelector(
    state => state.partials.canUseAllProductsWithCustomer,
  );

  /**
   * Clears state on product change
   */
  useEffect(() => {
    if (formType === "edit") {
      setProductToFetch(orderProduct?.products[0].product || null);
    }
  }, [formType, orderProduct]);

  const apiHook = (() => {
    if (order.customer) {
      if (order.customer.canAddNewIndexes) {
        return productsActions.useGetProductEntity;
      }

      if (!productToFetch?.isInProductSet && canUseAllProductsWithCustomer && FLAVOR === "main") {
        return productsActions.useGetProductEntity;
      }

      return FLAVOR === "main"
        ? productSetActions.useGetCustomerElements
        : productSetActions.useGetCustomerElementsB2b;
    }

    return productsActions.useGetProductEntity;
  })();

  const getProductId = () => {
    if (
      !order.customer ||
      order.customer.canAddNewIndexes ||
      (!productToFetch?.isInProductSet && canUseAllProductsWithCustomer && FLAVOR === "main")
    ) {
      return productToFetch?.id;
    }

    return `${order.customer.id}-${productToFetch?.id}`;
  };

  const { data: productEntity, error, isLoading: isFetchingProduct } = apiHook(
    getProductId() + queryString.stringify({ excludeIndexes: true, excludeIndexesData: true }),
    {
      enabled: Boolean(productToFetch),
    },
  );

  const {
    data: indexes,
    error: indexesError,
    isLoading: isFetchingIndexes,
    isFetchedAfterMount,
  } = productsActions.useGetProductIndexes(
    queryString.stringify({
      products: productEntity?.products.map(el => el.id) || [],
      customer: order.customer && !order?.customer.canAddNewIndexes ? order?.customer!.id : "",
    }),
    {
      enabled: Boolean(productEntity),
    },
  );

  const {
    data: indexesPrices,
    isFetchedAfterMount: indexesPricesFetchedAfterMount,
  } = productsActions.useGetProductIndexesPrice(
    queryString.stringify({
      products: productEntity?.products.map(el => el.id) || [],
      priceList: order.customer?.priceList?.id || "",
    }),
    {
      enabled: Boolean(productEntity && order.customer?.priceList),
    },
  );

  const initialValues: Values = useMemo(() => {
    if (!productEntity?.id) {
      return {
        productElements: [
          {
            ...initialProductElement,
            product: 0,
            cuid: cuid(),
            attributesState: [],
            productSetCode: "",
            id: 0,
            name: "",
            amount: "0",
            currency: order.salesAccount.currency || "PLN",
          },
        ],
      };
    }

    if (formType === "edit") {
      assertIsDefined(orderProduct?.id);
      return getInitialStateForEdit(
        orderProduct.products,
        orderProduct.productSetCode,
        indexes || {},
      );
    }

    return getInitialStateForCreate({
      products: productEntity?.products,
      productSetCode: productEntity?.productSetCode,
      orderSalesAccount: order.salesAccount,
      orderCustomer: order.customer,
      customers,
      indexes: indexes || {},
      indexesPrices: indexesPrices || {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderProduct?.id,
    productEntity?.id,
    order.customer,
    customers,
    formType,
    isFetchedAfterMount,
    indexesPricesFetchedAfterMount,
  ]);

  const attributesToDisplay = useMemo(
    () => productEntity?.commonAttributes.map(attribute => ({ ...attribute, cuid: cuid() })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productEntity],
  );

  function validate(values: Values) {
    const errors: Partial<{
      productElements: string;
    }> = {};
    if (values.productElements.some(el => Number(el.amount) < 0)) {
      errors.productElements = "Kwota nie może być ujemna.";
    }

    if (!values.productElements.every(el => el.index)) {
      errors.productElements = productEntity?.productSetCode
        ? "Wybierz wszystkie warianty produktów"
        : "Wybierz wariant produktu";
    }

    return errors;
  }

  return (
    <div className={styles.form}>
      <div
        className={cx(styles.section, {
          "px-0": overrides.removeHorizontalPadding,
        })}
      >
        {formType === "create" && !overrides.CustomSearchProductComponent && (
          <SearchProduct
            isService={isService}
            order={order}
            setProductToFetch={setProductToFetch}
          />
        )}
        {overrides.CustomSearchProductComponent &&
          overrides.CustomSearchProductComponent({ onChange: setProductToFetch })}

        {error || indexesError ? (
          <CommonError status={error?._httpStatus_ || indexesError?._httpStatus_} />
        ) : null}

        <div className="d-flex justify-content-center">
          {(isFetchingProduct || isFetchingIndexes) && <Spinner color="blue" />}
        </div>
      </div>
      {productEntity ? (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, helpers) => {
            if (formType === "create") {
              setProductToFetch(null);

              return submitCreateProduct?.(values, helpers, productEntity);
            }
            assertIsDefined(orderProduct);
            submitEditProduct?.(values, helpers, productEntity, orderProduct.id);
            closeForm?.();
          }}
          validate={validate}
          enableReinitialize
        >
          {({
            handleSubmit,
            values,
            setFieldValue,
            isValid,
            isSubmitting,
            setValues,
            submitForm,
          }) => {
            return (
              <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
                {Boolean(productEntity.commonAttributes.length) && (
                  <div
                    className={cx(styles.section, styles.commonAttributes, {
                      "px-0": overrides.removeHorizontalPadding,
                    })}
                  >
                    <h6>Cechy wspólne</h6>
                    <small className="text-color-grey">
                      <i>Wybrane opcje zostaną zastosowane do wszystkich produktów z tą cechą</i>
                    </small>
                    <AttributeSection
                      isCommonAttributes
                      attributesState={generateCommonAttributesState(
                        productEntity,
                        values.productElements,
                      )}
                      attributesToDisplay={attributesToDisplay || []}
                      order={order}
                      productsIds={productEntity.products.map(el => el.id)}
                      preselectedAttributes={[]}
                      changeAttribute={(attributeId, value, attributesState, productIndexes) => {
                        setValues(
                          immer(values, draft => {
                            draft.productElements.forEach(productElement => {
                              const product = productEntity.products.find(
                                el => el.id === productElement.id,
                              );
                              if (product && isMoreThanOneAttributeValue(product, attributeId)) {
                                const productToUpdate = productElement.attributesState.find(
                                  el => el.attributeId === attributeId,
                                );

                                const possibleValues = (() => {
                                  const indexes = Object.keys(productIndexes || {});

                                  const values: string[][] = [];
                                  indexes.forEach(index => {
                                    const indexArray = index.split("-");
                                    values.push(indexArray);
                                  });

                                  return [...new Set(values.flat())].map(Number);
                                })();

                                if (productToUpdate) {
                                  if (!value) return (productToUpdate.valueId = value);

                                  if (possibleValues?.includes(value)) {
                                    productToUpdate.valueId = value;
                                  }
                                }
                              }
                            });
                          }),
                        );
                        setValues(
                          immer(values, draft => {
                            draft.productElements.forEach(productElement => {
                              const product = productEntity.products.find(
                                el => el.id === productElement.id,
                              );
                              if (product && isMoreThanOneAttributeValue(product, attributeId)) {
                                const productToUpdate = productElement.attributesState.find(
                                  el => el.attributeId === attributeId,
                                );

                                if (productToUpdate) {
                                  const index = getProductIndexBasedOnAttributesState(
                                    productIndexes,
                                    productElement.attributesState,
                                  );

                                  if (index) {
                                    productElement.index = index;
                                  }
                                }
                              }
                            });
                          }),
                        );
                      }}
                    />
                  </div>
                )}
                {values.productElements.map((value, index) => (
                  <div className={cx({ "bg-white": index % 2 !== 0 })} key={value.cuid}>
                    <SingleProductForm
                      isTogglingEnable={formType === "create"}
                      product={productEntity.products[index]}
                      orderProduct={undefined}
                      setFieldValue={(name, value) => {
                        setFieldValue(`productElements[${index}][${name}]`, value);
                      }}
                      values={values.productElements[index]}
                      formType={formType}
                      order={order}
                      productToFetch={productToFetch}
                      valueIndex={index}
                      showAmountPicker={showAmountPicker}
                      showQuantityPicker={showQuantityPicker}
                      changeAttribute={(attributeId, value, cuid, productIndexes) => {
                        const valuesWithSelectedAttribute: Values = immer(values, draft => {
                          const productToUpdate = draft.productElements.find(
                            el => el.cuid === cuid,
                          );
                          assertIsDefined(productToUpdate);

                          const toChange = productToUpdate.attributesState.find(
                            el => el.attributeId === attributeId,
                          );

                          if (toChange) {
                            toChange.valueId = value;
                          }
                        });

                        const valuesWithUpdatedIndex = immer(valuesWithSelectedAttribute, draft => {
                          const productToUpdate = draft.productElements.find(
                            el => el.cuid === cuid,
                          );
                          assertIsDefined(productToUpdate);

                          const index = getProductIndexBasedOnAttributesState(
                            productIndexes,
                            productToUpdate.attributesState,
                          );

                          if (index) {
                            productToUpdate.index = index;
                          }
                        });

                        setValues(valuesWithUpdatedIndex);
                      }}
                    />
                  </div>
                ))}

                <div
                  className={cx("text-right", styles.section, {
                    "px-0": overrides.removeHorizontalPadding,
                  })}
                >
                  <ErrorMessage name="productElements" />
                </div>
                <div
                  className={cx("d-flex align-items-center justify-content-end", styles.section, {
                    "px-0": overrides.removeHorizontalPadding,
                  })}
                >
                  <Button
                    kind="secondary-stroke"
                    className="mr-2"
                    size="small"
                    onClick={() => closeForm?.()}
                  >
                    <span>Anuluj</span>
                  </Button>
                  <Button kind="primary" onClick={submitForm} size="small" disabled={isSubmitting}>
                    <span>{{ create: overrides.addButtonText, edit: "Gotowe" }[formType]}</span>
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      ) : (
        <div
          className={cx("d-flex justify-content-end ", styles.section, {
            "px-0": overrides.removeHorizontalPadding,
          })}
        >
          {closeForm && (
            <Button
              kind="secondary-stroke"
              size="small"
              className="mr-1"
              onClick={() => closeForm?.()}
            >
              <span>Zamknij</span>
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
