import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { orderKeys } from "./keys";
import {
  AddOrdersToUnloadingResponse,
  BulkAssignOrdersToExpressServiceDTO,
  CreateExpressServiceDTO,
  CreateOrderGroupResponse,
  ExpressService,
  FreeSingleItemQuantitiesDetails,
  FreeSingleItemQuantitiesListItem,
  ManufacturingItemProgress,
  ManufacturingOrder,
  ManufacturingOrderDetails,
  Order,
  OrderAttachment,
  OrderForInvoicing,
  OrderShippingProgressItem,
  OrderSingleItemQuantityWarehouseLocation,
  OrderWarehouseLocation,
  OrdersGroupByInfo,
  OrdersSalesAccountInfo,
  PostOrderCopyDTO,
} from "./models";
import { Product } from "api/products/models";
import { Assign } from "utility-types";
import { PartialOf } from "typeUtilities";
import { parsePatchData } from "utilities/parsePatchData";
import { createPreviewFactory } from "utilities/createPreviewMiddleware";
import { formatSignatureForPreview } from "utilities";

const getOrdersForInvoicing = (search: string = ""): ApiFetcher<Pagination<OrderForInvoicing>> => ({
  key: orderKeys.ordersForInvoicing(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/for-invoicing" + search,
    }),
});

const bulkPatchOrders = (data: Assign<PartialOf<Order>, { orders: number[] }>) =>
  queryFetch<void>({
    method: "PATCH",
    url: "/orders/items/bulk-update",
    data,
  });

const getOrderWarehouseLocations = (
  search: string = "",
): ApiFetcher<Pagination<OrderWarehouseLocation>> => ({
  key: orderKeys.orderWarehouseLocations(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/warehouse-locations" + search,
    }),
});

const getSingleItemQuantityWarehouseLocations = (
  search: string = "",
): ApiFetcher<Pagination<OrderSingleItemQuantityWarehouseLocation>> => ({
  key: orderKeys.singleItemQuantityWarehouseLocations(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/single-item-quantity-warehouse-locations" + search,
    }),
});

const getOrdersFreeSingleItemQuantities = (
  search: string = "",
): ApiFetcher<Pagination<FreeSingleItemQuantitiesListItem>> => ({
  key: orderKeys.ordersFreeSingleItemQuantities(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/free-single-item-quantities" + search,
    }),
});

const getOrderFreeSingleItemsQuantitiesDetails = (
  id: UUID,
): ApiFetcher<FreeSingleItemQuantitiesDetails> => ({
  key: orderKeys.orderFreeSingleItemQuantitiesDetails(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/orders/free-single-item-quantities/${id}`,
    }),
});

const getManufacturingOrders = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturingOrder>> => ({
  key: orderKeys.manufacturingOrders(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/manufacturing" + search,
    }),
});

const getOrdersGroupByInfo = (search: string = ""): ApiFetcher<OrdersGroupByInfo[]> => ({
  key: orderKeys.ordersGroupByInfo(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/group-by-info" + search,
    }),
});

const getOrdersSalesAccountInfo = (search: string = ""): ApiFetcher<OrdersSalesAccountInfo[]> => ({
  key: orderKeys.ordersSalesAccountInfo(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/sales-account-info" + search,
    }),
});

const getManufacturingOrderDetails = (orderId: number): ApiFetcher<ManufacturingOrderDetails> => ({
  key: orderKeys.manufacturing.orderDetails(orderId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/manufacturing/" + orderId,
    }),
});

const getOrderManufacturingSingleItemQuantities = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturingItemProgress>> => ({
  key: orderKeys.orderManufacturingSingleItemQuantities(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/manufacturing-single-item-quantities" + search,
    }),
});

const postOrderCopy = (data: PostOrderCopyDTO) =>
  queryFetch<void>({
    method: "POST",
    url: "/orders/create-copy",
    data,
  });

const postAwaitingTransport = (data: { ordersIds: number[] }) =>
  queryFetch<void>({
    method: "POST",
    url: "orders/create-awaiting-transport",
    data,
  });

const postSetExternalStatusAsSent = (data: { ordersIds: number[] }) =>
  queryFetch<void>({
    method: "POST",
    url: "/marketplace-statuses/set-external-status-as-sent",
    data,
  });

const postUpdateItemsAutoReservations = (data: {
  itemsIds?: Product["id"][];
  singleItemQuantitiesIds?: UUID[];
  exclude: boolean;
}) =>
  queryFetch<{ message: "ok" }>({
    method: "POST",
    url: "/orders/update-items-auto-reservations",
    data,
  });

const getAttachments = (search: string = ""): ApiFetcher<Pagination<OrderAttachment>> => ({
  key: orderKeys.attachments(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/attachments" + search,
    }),
});

/**
 * @argument data = {order: UUID, file: File}
 */
const postAttachment = (args: {
  data: FormData;
  onUploadProgress: (progressEvent: ProgressEvent) => void;
}) =>
  queryFetch<OrderAttachment>({
    method: "POST",
    url: "/orders/attachments",
    data: args.data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.csv"',
    },
    onUploadProgress: args.onUploadProgress,
  });

const deleteAttachment = (id: OrderAttachment["id"]) =>
  queryFetch<OrderAttachment>({
    method: "DELETE",
    url: "/orders/attachments/" + id,
  });

const createUnloadingFromOrdersList = (data: { orderIds: number[] }) =>
  queryFetch<AddOrdersToUnloadingResponse>({
    method: "POST",
    url: "/orders/create-unloading-base-on-order-ids",
    data,
  });

const createOrderGroupFromOrders = (data: { ordersIds: number[]; force: boolean }) =>
  queryFetch<CreateOrderGroupResponse>({
    method: "POST",
    url: "/orders/items/create-order-group",
    data,
  });

// EXPRESS SERVICES

const getExpressServices = (search: string = ""): ApiFetcher<Pagination<ExpressService>> => ({
  key: orderKeys.expressServices.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/express-services" + search,
    }),
});

const postExpressService = (data: CreateExpressServiceDTO) =>
  queryFetch<ExpressService>({
    method: "POST",
    url: "/orders/express-services",
    data,
  });

const patchExpressService = (data: Assign<Partial<ExpressService>, { id: ExpressService["id"] }>) =>
  queryFetch<ExpressService>({
    method: "PATCH",
    url: "/orders/express-services/" + data.id,
    data: parsePatchData(data),
  });

const patchOrder = (data: Assign<Partial<Order>, { id: number }>) =>
  queryFetch<Order>({
    method: "PATCH",
    url: "/orders/items/" + data.id,
    data: parsePatchData(data),
  });

const assignOrdersToExpressService = (data: BulkAssignOrdersToExpressServiceDTO) =>
  queryFetch<void>({
    method: "POST",
    url: "/orders/assign-orders-to-express-service",
    data,
  });

const getOrdersShippingProgress = (
  search: string = "",
): ApiFetcher<Pagination<OrderShippingProgressItem>> => ({
  key: orderKeys.ordersShippingProgress.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/view-modules/orders/orders-shipping-progress" + search,
    }),
});

const addItemToComplaint = (data: {
  items: {
    quantity: number;
    productId: number;
    maxComplaintQuantity: number;
    isAddedToComplaint: boolean;
    indexId: number;
    note: string;
    packages: {
      package: number;
      isAddedToComplaint: boolean;
      quantity: number;
    }[];
  }[];
  orderId: Order["id"];
}) =>
  queryFetch<"ok">({
    method: "POST",
    url: `/orders/add-item-to-complaint/${data.orderId}`,
    data,
  });

export const orderPreviewFactory = createPreviewFactory({
  orderPhotosPDF: (orderId: number) => ({
    url: `/orders/pdf-with-delivery-photo?orders=${orderId}`,
  }),
  orderProtocolPDF: (orderId: number, signature: string) => ({
    url: `/orders/protocol-pdf/${orderId}/${signature}`,
  }),
  complaintPDF: (orderId: number, signature: string) => ({
    url: `/orders/complaint-pdf/${orderId}/${signature}`,
  }),
  labelsLikeWarehouse: (order: Order) => ({
    url: `/utils/labels-like-warehouse/${formatSignatureForPreview(order.signature)}?orders=${
      order.id
    }`,
  }),
  labelsByPackage: (order: Order) => ({
    url: `/utils/labels-by-package/${formatSignatureForPreview(order.signature)}?orders=${
      order.id
    }`,
  }),
  labelsByPackageB2B: (ordersIds: string, fileName: string, search: string) => ({
    url: `/utils/labels-by-package/${fileName}-${ordersIds}` + search,
  }),
  complaintLabel: (order: Order) => ({
    url: `/utils/complaint-labels/${order.id}`,
  }),
  packagesLabels: (search: string) => ({
    url: `/packages/print-labels` + search,
  }),
  siqpPreview: (search: string) => ({
    url: `/labels/siqp-preview` + search,
  }),
  orderLabel: (orderId: number, orderSignature: string) => ({
    url: `/utils/labels/order/${orderId}/${formatSignatureForPreview(orderSignature)}`,
  }),
});

export const orderApi = {
  assignOrdersToExpressService,
  getExpressServices,
  getOrderWarehouseLocations,
  getSingleItemQuantityWarehouseLocations,
  getOrdersFreeSingleItemQuantities,
  getOrderFreeSingleItemsQuantitiesDetails,
  getManufacturingOrderDetails,
  getManufacturingOrders,
  getOrdersForInvoicing,
  getOrdersGroupByInfo,
  getOrdersSalesAccountInfo,
  getOrdersShippingProgress,
  getOrderManufacturingSingleItemQuantities,
  postAwaitingTransport,
  postAttachment,
  postSetExternalStatusAsSent,
  getAttachments,
  deleteAttachment,
  createUnloadingFromOrdersList,
  postUpdateItemsAutoReservations,
  createOrderGroupFromOrders,
  bulkPatchOrders,
  postOrderCopy,
  patchExpressService,
  addItemToComplaint,
  postExpressService,
  patchOrder,
};
