import { orderFileFactory } from "api/orders/calls";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { Order, OrderAttachment } from "./models";
import { StatusHandlerHelpers } from "components/utils";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { useErrorToastr } from "hooks/useErrorToastr";
import { fileDownloader } from "fileDownloader";
import { createApiQuery } from "hooks/createApiQuery";
import { orderApi } from "./api";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";
import { Pagination } from "api/types";
import { orderKeys } from "./keys";
import produce from "immer";
import { getAttachmentSearch } from "pages/orders/ordersList/rightPanel/attachmentsSection/AttachmentsSection";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useQuery, useRedux } from "hooks";
import { ordersKeys } from "api/keys";

const useSingleItemQuantityWarehouseLocations = createPaginatedApiQuery(
  orderApi.getSingleItemQuantityWarehouseLocations,
);

const useAttachments = createPaginatedApiQuery(orderApi.getAttachments);
const useExpressServices = createPaginatedApiQuery(orderApi.getExpressServices);

const useGetOrdersFreeSingleItemQuantities = createPaginatedApiQuery(
  orderApi.getOrdersFreeSingleItemQuantities,
);

const useGetOrderManufacturingSingleItemQuantities = createPaginatedApiQuery(
  orderApi.getOrderManufacturingSingleItemQuantities,
);
const useGetOrderFreeSingleItemsQuantitiesDetails = createApiQuery(
  orderApi.getOrderFreeSingleItemsQuantitiesDetails,
);

const useOrdersGroupByInfo = createApiQuery(orderApi.getOrdersGroupByInfo);

const useOrdersForInvoicing = createPaginatedApiQuery(orderApi.getOrdersForInvoicing);

const useManufacturingOrderDetails = createApiQuery(orderApi.getManufacturingOrderDetails);

const useOrdersSalesAccountInfo = createApiQuery(orderApi.getOrdersSalesAccountInfo);

const useOrdersShippingProgress = createPaginatedApiQuery(orderApi.getOrdersShippingProgress);

const useManufacturingOrders = createPaginatedApiQuery(orderApi.getManufacturingOrders);
const useDownloadOrderSpecificationPdf = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (order: Order, helpers: StatusHandlerHelpers) => {
    helpers.startFetching();
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = orderFileFactory.orderSpecificationPdf(order);
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    helpers.stopFetching();
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useUpdateItemsAutoReservations = () =>
  useMutation(
    orderApi.postUpdateItemsAutoReservations,
    ({ queryUtils }) => queryUtils.handleCommonResponse,
  );

const usePostAwaitingTransport = () => {
  return useMutation(orderApi.postAwaitingTransport, ({ toastr, queryClient }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono awizację",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useCreateOrderCopy = () => {
  return useMutation(orderApi.postOrderCopy, ({ queryUtils, toastr }) => ({
    ...queryUtils.handleCommonResponse,
    onSuccess: () => {
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono kopię zamówienia",
      });
      queryUtils.handleCommonResponse.onSuccess();
    },
  }));
};

const useBulkAssignOrdersToExpressService = () => {
  return useMutation(orderApi.assignOrdersToExpressService, ({ queryUtils, toastr }) => ({
    ...queryUtils.handleCommonResponse,
    onSuccess: () => {
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Zmieniono szczegóły przesyłki ekspresowej dla wybranych zamówień",
      });
      queryUtils.handleCommonResponse.onSuccess();
    },
  }));
};

const useSetExternalStatusAsSent = () => {
  return useMutation(orderApi.postSetExternalStatusAsSent, ({ queryUtils, toastr }) => ({
    ...queryUtils.handleCommonResponse,
    onSuccess: () => {
      toastr.open({
        text: "Zlecono zmianę statusu wybranych zamówień z marketplace jako wysłanych",
        title: "Udało się!",
        type: "success",
      });
      queryUtils.handleCommonResponse.onSuccess();
    },
  }));
};

const usePostAttachment = ({ order }: { order: Order }) => {
  return useMutation(orderApi.postAttachment, ({ toastr, queryClient }) => ({
    onSuccess: payload => {
      queryClient.setQueryData<Pagination<OrderAttachment>>(
        orderKeys.attachments(getAttachmentSearch(order)),
        data => {
          return produce(data!, draft => {
            if (!draft?.results) return draft;
            draft.results = [payload, ...draft.results];
          });
        },
      );
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Dodano dokument do zamówienia",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useDeleteAttachment = () => {
  return withDeleteConfirmation(
    useMutation(orderApi.deleteAttachment, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto dokument`,
        });
        queryClient.invalidateQueries();
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
  )();
};

const useCreateOrderGroupFromOrders = () =>
  useMutation(orderApi.createOrderGroupFromOrders, ({ toastr, queryClient }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
    onError: error => {
      //@ts-ignore
      if (!error?.ordersWithExternalTransport) {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      }
    },
  }));

const useCreateUnloadingFromOrders = () =>
  useMutation(orderApi.createUnloadingFromOrdersList, ({ toastr, queryClient }) => ({
    onSuccess: payload => {
      queryClient.invalidateQueries();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

const useBulkPatchOrders = () => {
  return useMutation(orderApi.bulkPatchOrders, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Zmodyfikowano wybrane zamówienia",
      });
      queryClient.invalidateQueries();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePatchExpressService = () => {
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  return useMutation(orderApi.patchExpressService, ({ queryUtils }) => ({
    onMutate: ({ id, ...toUpdate }) => {
      const prevList = queryUtils.handlePaginatedListUpdate(
        orderKeys.expressServices.list(),
        id,
        toUpdate,
      );

      return { prevList };
    },
    onSuccess: () => {
      refetchPartials();
    },
    onError: (_, { id }, onMutationReturn) => {
      assertIsDefined(onMutationReturn);
      queryUtils.rollbackList(orderKeys.expressServices.list(), onMutationReturn.prevList, id);
    },
  }));
};

const usePostExpressService = (close: () => void) => {
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  return useMutation(orderApi.postExpressService, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      close();
      refetchPartials();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Dodano etykietę",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePatchOrder = () => {
  const { query } = useQuery();
  const { panelId } = query;

  return useMutation(orderApi.patchOrder, ({ queryUtils }) => ({
    onMutate: ({ id, ...toUpdate }) => {
      const prevOrder = queryUtils.handleMutate<Order>(ordersKeys.order(panelId), toUpdate);
      const prevListOrder = queryUtils.handlePaginatedListUpdate(ordersKeys.orders(), id, toUpdate);
      return { prevOrder, prevListOrder };
    },
    onError: (error, { id }, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollback(ordersKeys.order(panelId), onMutateReturn.prevOrder, error);
      queryUtils.rollbackList(ordersKeys.orders(), onMutateReturn.prevListOrder, id);
    },
  }));
};

const useAddItemToComplaint = () =>
  useMutation(orderApi.addItemToComplaint, ({ toastr }) => ({
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

export const orderActions = {
  useBulkAssignOrdersToExpressService,
  useExpressServices,
  useSingleItemQuantityWarehouseLocations,
  useDownloadOrderSpecificationPdf,
  useGetOrdersFreeSingleItemQuantities,
  useGetOrderFreeSingleItemsQuantitiesDetails,
  useOrdersForInvoicing,
  useOrdersGroupByInfo,
  useOrdersSalesAccountInfo,
  useOrdersShippingProgress,
  useManufacturingOrderDetails,
  useManufacturingOrders,
  useGetOrderManufacturingSingleItemQuantities,
  usePostAwaitingTransport,
  useAttachments,
  usePostAttachment,
  useDeleteAttachment,
  useCreateUnloadingFromOrders,
  useUpdateItemsAutoReservations,
  useCreateOrderGroupFromOrders,
  useBulkPatchOrders,
  useSetExternalStatusAsSent,
  useCreateOrderCopy,
  usePatchExpressService,
  usePostExpressService,
  usePatchOrder,
  useAddItemToComplaint,
};
