export enum PriorityChoices {
  NORMAL = "NORMAL",
  HIGH = "HIGH",
  CRITICAL = "CRITICAL",
}

export enum OrderTypeChoices {
  STANDARD = "STANDARD",
  COMPLAINT = "COMPLAINT",
}

export enum OrderPaymentTypeChoices {
  DROP_SHIPPING = "DROP_SHIPPING",
  AMOUNT = "AMOUNT",
}

export enum OrderReleaseStatus {
  FINISHED = "FINISHED",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_STARTED = "NOT_STARTED",
}
