import ambro from "assets/images/shippingService/ambro.svg";
import mebelTaxi from "assets/images/shippingService/mebelTaxi.svg";
import dpd from "assets/images/shippingService/dpd.svg";
import gls from "assets/images/shippingService/gls.svg";
import spt from "assets/images/shippingService/spt.svg";
import dmtrans from "assets/images/shippingService/dmtrans.svg";
import zadbano from "assets/images/shippingService/zadbano.svg";
import bejotLogistics from "assets/images/shippingService/bejotlogistics.png";
import inpost from "assets/images/shippingService/inpost.png";
import pocztex from "assets/images/shippingService/pocztex.jpg";
import aj_transport from "assets/images/shippingService/aj_transport.jpg";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { OrderTypeChoice } from "api/shipping/zadbano/enum";
import { InpostCourierService } from "api/shipping/inpost/enum";

export enum ShippingService {
  AMBRO = "AMBRO",
  MEBEL_TAXI = "MEBEL_TAXI",
  DPD = "DPD",
  GLS = "GLS",
  ZADBANO = "ZADBANO",
  SPT = "SPT",
  AJ_TRANSPORT = "AJ_TRANSPORT",
  BJ_LOGISTICS = "BJ_LOGISTICS",
  POCZTEX = "POCZTEX",
  INPOST = "INPOST",
  DM_TRANS = "DM_TRANS",
  "NOT CONNECTED" = "NOT CONNECTED",
}

export const shippingServiceConstants: Record<
  ShippingService,
  {
    logo: string;
    externalStatus: string[];
    name: string;
    permissions: {
      canUpdate: {
        trackingNumber: boolean;
      };
    };
  }
> = {
  AMBRO: {
    logo: ambro,
    name: "Ambro",
    externalStatus: [
      "Anulowano",
      "Anulowano zwrot",
      "Odbiorca odmówił przyjęcia",
      "Awizo",
      "Awizo II",
      "Zwrot dokumentów",
      "Błędne dane adresowe",
      "Brak przesyłki",
      "Brak czasu",
      "Błędny rejon doręczenia",
      "Brak Gotówki",
      "Błędny sort",
      "Doręczono",
      "Doręczono przesyłkę wewnętrzną",
      "Do wyjaśnienia",
      "Wydrukowano DWP",
      "Etykieta Przeterminowana",
      "IDWO",
      "Inny status",
      "Interwencja",
      "Inwentaryzacja stanu magazynowego",
      "Likwidacja przesyłki",
      "Mylny Kierunek",
      "Kontakt telefoniczny nieudany",
      "Nadana w POK",
      "Odbiór własny",
      "Odebrana z POK",
      "Przeadresowanie",
      "Przekazano do doręczenia",
      "Powrót do oddziału",
      "Przyjęta od Klienta przez Kuriera",
      "Przyjęta od kuriera przez magazyn",
      "Przekierowanie na adres",
      "Przekierowanie na PM/POK",
      "Posortowana",
      "Przygotowana przez nadawcę",
      "Przyjęta w oddziale",
      "Przyjęta w POK",
      "Przyjęta w sortowni",
      "Przesyłka uszkodzona",
      "Umówiony termin doręczenia",
      "Weryfikacja wagi przesyłki",
      "Wysłana z oddziału",
      "Wysłana z sortowni",
      "Zaginiona",
      "Zlecenie oczekujące do realizacji",
      "Zwrot do magazynu przesyłek Niedoręczalnych",
      "Zwrot do nadawcy",
      "Zlecenie odrzucone przez kuriera",
      "Błędna trasa",
      "Zlecenie Przyjęte od Klienta",
      "Zlecenie powielone",
      "Przesyłka nieprzygotowana",
      "Zlecenie przeniesione",
      "Wydanie zlecenia na kuriera",
      "Zwrot",
      "Zwrot z POK",
      "Zlecenie Zrealizowane",
    ],
    permissions: { canUpdate: { trackingNumber: false } },
  },
  MEBEL_TAXI: {
    logo: mebelTaxi,
    name: "Mebel taxi",
    externalStatus: [
      "Przesyłka zarejestrowana w systemie",
      "Przesyłka na magazynie",
      "Przesyłka wysłana",
      "Przesyłka odebrana",
      "Przesyłka anulowana",
    ],
    permissions: { canUpdate: { trackingNumber: false } },
  },
  DM_TRANS: {
    logo: dmtrans,
    externalStatus: [
      "Przesyłka zarejestrowana w systemie",
      "Przesyłka na magazynie",
      "Przesyłka wysłana",
      "Przesyłka odebrana",
      "Przesyłka anulowana",
    ],
    name: "DM Trans",
    permissions: { canUpdate: { trackingNumber: false } },
  },
  DPD: {
    logo: dpd,
    name: "DPD",
    externalStatus: [],
    permissions: { canUpdate: { trackingNumber: false } },
  },
  POCZTEX: {
    logo: pocztex,
    name: "Pocztex",
    externalStatus: [],
    permissions: { canUpdate: { trackingNumber: false } },
  },
  GLS: {
    logo: gls,
    name: "GLS",
    externalStatus: [
      "Dane paczki",
      "W tranzycie",
      "Paczka zarejestrowana w filii GLS",
      "W dostarczeniu",
      "Paczka dostarczona",
    ],
    permissions: { canUpdate: { trackingNumber: false } },
  },
  SPT: {
    logo: spt,
    name: "SPT",
    externalStatus: [],
    permissions: { canUpdate: { trackingNumber: false } },
  },
  ZADBANO: {
    logo: zadbano,
    name: "ZADBANO",
    externalStatus: [],
    permissions: { canUpdate: { trackingNumber: false } },
  },
  BJ_LOGISTICS: {
    logo: bejotLogistics,
    name: "BJ Logistics",
    externalStatus: [],
    permissions: { canUpdate: { trackingNumber: true } },
  },
  INPOST: {
    logo: inpost,
    name: "Inpost",
    externalStatus: [
      "Przesyłka zarejestrowana w systemie",
      "Przygotowana przez Nadawcę",
      "Paczka nadana w automacie Paczkomat",
      "Odebrana od klienta",
      "Odebrana od Nadawcy",
      "Przyjęta w oddziale InPost",
      "W trasie",
      "Czeka na odbiór w PaczkoPunkcie",
      "Przesyłka ponadgabarytowa",
      "Przyjęta w Sortowni",
      "Wysłana z Sortowni",
      "Przyjęta w Oddziale Docelowym",
      "Przekazano do doręczenia",
      "Umieszczona w automacie Paczkomat",
      "Przypomnienie o czekającej paczce",
      "Dostarczona",
      "Upłynął termin odbioru",
      "Powrót do oddziału",
      "Zareklamowana w automacie Paczkomat",
      "Zwrot do nadawcy",
      "Anulowano etykietę",
      "Nadana w PaczkoPunkcie",
      "W doręczeniu",
      "Odmowa przyjęcia",
      "Brak możliwości doręczenia",
      "W drodze do oddziału nadawczego InPost",
      "Przekazanie do magazynu przesyłek niedoręczalnych",
      "Przygotowano dokumenty zwrotne",
      "Paczka nieodebrana – czeka w Oddziale",
      "Możliwe opóźnienie doręczenia",
      "Przekierowano do automatu Paczkomat",
      "Anulowano przekierowanie",
      "Przekierowano na inny adres",
      "Paczka magazynowana w PaczkoPunkcie",
      "Upłynął termin odbioru paczki magazynowanej",
      "W drodze do wybranego automatu Paczkomat",
      "Zwrócona do nadawcy",
      "Paczka magazynowana w tymczasowym automacie Paczkomat",
      "Paczka w drodze do pierwotnie wybranego automatu Paczkomat",
    ],
    permissions: { canUpdate: { trackingNumber: false } },
  },
  AJ_TRANSPORT: {
    logo: aj_transport,
    name: "AJ Transport",
    externalStatus: [],
    permissions: { canUpdate: { trackingNumber: false } },
  },
  "NOT CONNECTED": {
    logo: "",
    name: EMPTY_VALUE,
    externalStatus: [],
    permissions: { canUpdate: { trackingNumber: false } },
  },
};

export const getInpostCourierService = (inpostCourierService: InpostCourierService) => {
  switch (inpostCourierService) {
    case InpostCourierService.inpost_courier_standard:
      return { text: "Standard" };

    case InpostCourierService.inpost_courier_express_1000:
      return { text: "Doręczenie do 10:00" };

    case InpostCourierService.inpost_courier_express_1200:
      return { text: "Doręczenie do 12:00" };

    case InpostCourierService.inpost_courier_express_1700:
      return { text: "Doręczenie do 17:00" };

    case InpostCourierService.inpost_courier_palette:
      return { text: "Paleta Standard" };

    default:
      const exhaustiveCheck: never = inpostCourierService;
      console.error(`Unhandled inpost courier service: ${exhaustiveCheck}`);
      return { text: EMPTY_VALUE };
  }
};

export const getZadbanoOrderTypeConstants = (orderType: OrderTypeChoice) => {
  switch (orderType) {
    case OrderTypeChoice.DELIVERY: {
      return { name: "Dostawa" };
    }
    case OrderTypeChoice.RETURN: {
      return { name: "Zwrot" };
    }
    default:
      const exhaustiveCheck: never = orderType;
      console.error(`Unhandled order type: ${exhaustiveCheck}`);
      return { name: EMPTY_VALUE };
  }
};
